<template>
  <div class="content-area-wrapper container-xxl p-0">
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <!-- overlay container -->
        <div class="body-content-overlay"></div>

        <!-- file manager app content starts -->
        <div class="file-manager-main-content">
          <!-- search area start -->
          <div class="file-manager-content-header d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <div class="sidebar-toggle d-block d-xl-none float-start align-middle ms-1">
                <feather-icon icon="MenuIcon" class="font-medium-5"/>
              </div>
              <b-dropdown v-if="canCreateSubFolder || canUploadFile" variant="primary"
                          class="add-file-btn text-center w-100" :text="$t('fileManager.AddNew')">
                <b-dropdown-item v-if="canCreateSubFolder" @click="showNewFolderModal">
                  <div class="mb-0">
                    <feather-icon class="me-25" icon="FolderIcon"/>
                    <span class="align-middle">{{ $t('fileManager.NewFolder') }}</span>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item v-if="canUploadFile" @click="showNewFileModal">
                  <div class="mb-0">
                    <feather-icon class="me-25" icon="UploadCloudIcon"/>
                    <span class="align-middle">{{ $t('fileManager.FileUpload') }}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <div class="input-group input-group-merge shadow-none m-0 flex-grow-1">
                  <span class="input-group-text border-0">
                      <feather-icon icon="SearchIcon"/>
                  </span>
                <input type="text" class="form-control files-filter border-0 bg-transparent"
                       :placeholder="$t('table.filter.placeholder.search')"/>
              </div>
            </div>
          </div>
          <!-- search area ends here -->
          <perfect-scrollbar class="file-manager-content-body">
            <b-overlay
                :show="loading"
                :opacity="0.8"
                spinner-variant="primary"
                spinner-type="grow"
                rounded="sm"
            >
              <!-- Folders Container Starts -->
              <div class="view-container" :class="{'list-view': this.mode === 'list'}">
                <div class="files-header" style="margin-left: 0">
                  <h6 class="text-left fw-bold mb-0">{{ $t('fileManager.FileName') }}</h6>
                  <div>
                    <h6 class="fw-bold file-item-size d-inline-block mb-0">{{ $t('fileManager.Size') }}</h6>
                    <h6 class="fw-bold file-last-modified d-inline-block mb-0">{{ $t('fileManager.UploadedAt') }}</h6>
                    <h6 class="fw-bold d-inline-block me-1 mb-0">{{ $t('fileManager.Actions') }}</h6>
                  </div>
                </div>

                <div v-if="id" class="card file-manager-item folder"
                     @click="moveUp(parentId)">
                  <div class="card-img-top file-logo-wrapper">
                    <div class="d-none dropdown float-right">
                      <feather-icon icon="MoreVerticalIcon" class="toggle-dropdown mt-n25"/>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-100">
                      <i class="far fa-folder"></i>
                    </div>
                  </div>
                  <div class="card-body ps-2 pt-0 pb-1">
                    <div class="content-wrapper">
                      <p class="card-text file-name mb-0">..</p>
                    </div>
                  </div>
                </div>

                <div
                    v-for="folder in data"
                    :key="folder.id"
                    @click="$router.push({ name: 'file-manager-folder', params: { id: folder.uuid }})"
                    v-if="folder.mimeType === 'inode/directory'"
                    class="card file-manager-item folder"
                >
                  <div class="card-img-top file-logo-wrapper">
                    <b-dropdown class="float-right dropdown-no-padding" variant="link" no-caret style="padding: 0">
                      <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" class="toggle-dropdown mt-n25"/>
                      </template>
                      <b-dropdown-item @click.stop="downloadFolderAsZip(folder.uuid, folder.name)">
                        <feather-icon icon="DownloadIcon" class="align-middle me-50"/>
                        <span class="align-middle">{{ $t('fileManager.Download') }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="folder.canCreateSubFolder" @click.stop="showRenameFolderModal(folder)">
                        <feather-icon icon="EditIcon" class="align-middle me-50"/>
                        <span class="align-middle">{{ $t('fileManager.Rename') }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="folder.deletable"
                                       @click.stop="deleteFolder(folder.uuid, folder.deletable)">
                        <feather-icon icon="TrashIcon" class="align-middle me-50"/>
                        <span class="align-middle">{{ $t('fileManager.Delete') }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <div class="d-flex align-items-center justify-content-center w-100">
                      <i class="far fa-folder"></i>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="content-wrapper">
                      <p class="card-text file-name mb-0">{{ folder.alias }}</p>
                      <p class="card-text file-size mb-0">&nbsp;</p>
                      <p class="card-text file-date">{{ $formatters.formatLaravelTimestampToDate(folder.date) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Folders Container Ends -->

              <!-- Files Container Starts -->
              <div class="view-container" :class="{'list-view': this.mode === 'list'}">
                <div
                    v-for="file in data"
                    v-if="file.mimeType !== 'inode/directory'"
                    :key="file.id"
                    @click.stop="downloadFile(file.id, file.alias)"
                    class="card file-manager-item file"
                >
                  <div class="card-img-top file-logo-wrapper">
                    <b-dropdown class="float-right dropdown-no-padding" variant="link" no-caret style="padding: 0">
                      <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" class="toggle-dropdown mt-n25"/>
                      </template>
                      <b-dropdown-item v-if="file.canBePreviewed" @click.stop="previewFile(file.id)">
                        <feather-icon icon="EyeIcon" class="align-middle me-50"/>
                        <span class="align-middle">{{ $t('fileManager.Preview') }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click.stop="downloadFile(file.id, file.alias)">
                        <feather-icon icon="DownloadIcon" class="align-middle me-50"/>
                        <span class="align-middle">{{ $t('fileManager.Download') }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click.stop="showRenameFileModal(file)">
                        <feather-icon icon="EditIcon" class="align-middle me-50"/>
                        <span class="align-middle">{{ $t('fileManager.Rename') }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="file.deletable" @click.stop="deleteFile(file.id, file.deletable)">
                        <feather-icon icon="TrashIcon" class="align-middle me-50"/>
                        <span class="align-middle">{{ $t('fileManager.Delete') }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <div class="d-flex align-items-center justify-content-center w-100">

                      <i class="far fa-file-pdf" v-if="file.mimeType === 'application/pdf'" style="color: #e80d01"></i>

                      <i class="far fa-image" v-if="file.mimeType === 'image/bmp'" style="color: #0078d8"></i>
                      <i class="far fa-image" v-if="file.mimeType === 'image/gif'" style="color: #0078d8"></i>
                      <i class="far fa-image" v-if="file.mimeType === 'image/jpeg'" style="color: #0078d8"></i>
                      <i class="far fa-image" v-if="file.mimeType === 'image/png'" style="color: #0078d8"></i>
                      <i class="far fa-image" v-if="file.mimeType === 'image/svg+xml'" style="color: #0078d8"></i>
                      <i class="far fa-image" v-if="file.mimeType === 'image/webp'" style="color: #0078d8"></i>

                      <i class="fas fa-file-word" v-if="file.mimeType === 'application/msword'"
                         style="color: #2c5899"></i>
                      <i class="fas fa-file-word"
                         v-if="file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                         style="color: #2c5899"></i>
                      <i class="fas fa-file-word" v-if="file.mimeType === 'application/vnd.oasis.opendocument.text'"
                         style="color: #2c5899"></i>

                      <i class="fas fa-file-excel"
                         v-if="file.mimeType === 'application/vnd.oasis.opendocument.spreadsheet'"
                         style="color: #1d6a42"></i>
                      <i class="fas fa-file-excel" v-if="file.mimeType === 'application/vnd.ms-excel'"
                         style="color: #1d6a42"></i>
                      <i class="fas fa-file-excel"
                         v-if="file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                         style="color: #1d6a42"></i>

                      <i class="fas fa-file-powerpoint" v-if="file.mimeType === 'application/vnd.ms-powerpoint'"
                         style="color: #c54325"></i>
                      <i class="fas fa-file-powerpoint"
                         v-if="file.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
                         style="color: #c54325"></i>

                      <i class="fas fa-file-archive" v-if="file.mimeType === 'application/x-tar'"
                         style="color: #fec530"></i>
                      <i class="fas fa-file-archive" v-if="file.mimeType === 'application/zip'"
                         style="color: #fec530"></i>
                      <i class="fas fa-file-archive" v-if="file.mimeType === 'application/x-7z-compressed'"
                         style="color: #fec530"></i>

                      <i class="fas fa-file-alt" v-if="file.mimeType === 'text/plain'" style="color: #000000"></i>

                      <i class="fas fa-file-csv" v-if="file.mimeType === 'text/csv'" style="color: #1d6a42"></i>

                    </div>
                  </div>
                  <div class="card-body">
                    <div class="content-wrapper">
                      <p class="card-text file-name mb-0">{{ file.alias }}</p>
                      <p class="card-text file-size mb-0">{{ file.size }}</p>
                      <p class="card-text file-date">{{ $formatters.formatLaravelTimestampToDate(file.date) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Files Container Ends -->
            </b-overlay>
          </perfect-scrollbar>
        </div>
        <!-- file manager app content ends -->

        <!-- File Info Sidebar Starts-->
        <div class="modal modal-slide-in fade show" id="app-file-manager-info-sidebar">
          <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0">
              <div class="modal-header d-flex align-items-center justify-content-between mb-1 p-2">
                <h5 class="modal-title">menu.js</h5>
                <div>
                  <i data-feather="trash" class="cursor-pointer me-50" data-bs-dismiss="modal"></i>
                  <i data-feather="x" class="cursor-pointer" data-bs-dismiss="modal"></i>
                </div>
              </div>
              <div class="modal-body flex-grow-1 pb-sm-0 pb-1">
                <ul class="nav nav-tabs tabs-line" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#details-tab" role="tab"
                       aria-controls="details-tab" aria-selected="true">
                      <i data-feather="file"></i>
                      <span class="align-middle ms-25">Details</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#activity-tab" role="tab"
                       aria-controls="activity-tab" aria-selected="true">
                      <i data-feather="activity"></i>
                      <span class="align-middle ms-25">Activity</span>
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="details-tab" role="tabpanel"
                       aria-labelledby="details-tab">
                    <div class="d-flex flex-column justify-content-center align-items-center py-5">
                      <img src="/app-assets/images/icons/js.png" alt="file-icon" height="64"/>
                      <p class="mb-0 mt-1">54kb</p>
                    </div>
                    <h6 class="file-manager-title my-2">Settings</h6>
                    <ul class="list-unstyled">
                      <li class="d-flex justify-content-between align-items-center mb-1">
                        <span>File Sharing</span>
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="sharing"/>
                          <label class="form-check-label" for="sharing"></label>
                        </div>
                      </li>
                      <li class="d-flex justify-content-between align-items-center mb-1">
                        <span>Synchronization</span>
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" checked id="sync"/>
                          <label class="form-check-label" for="sync"></label>
                        </div>
                      </li>
                      <li class="d-flex justify-content-between align-items-center mb-1">
                        <span>Backup</span>
                        <div class="form-check form-switch">
                          <input type="checkbox" class="form-check-input" id="backup"/>
                          <label class="form-check-label" for="backup"></label>
                        </div>
                      </li>
                    </ul>
                    <hr class="my-2"/>
                    <h6 class="file-manager-title my-2">Info</h6>
                    <ul class="list-unstyled">
                      <li class="d-flex justify-content-between align-items-center">
                        <p>Type</p>
                        <p class="fw-bold">JS</p>
                      </li>
                      <li class="d-flex justify-content-between align-items-center">
                        <p>Size</p>
                        <p class="fw-bold">54kb</p>
                      </li>
                      <li class="d-flex justify-content-between align-items-center">
                        <p>Location</p>
                        <p class="fw-bold">Files > Documents</p>
                      </li>
                      <li class="d-flex justify-content-between align-items-center">
                        <p>Owner</p>
                        <p class="fw-bold">Sheldon Cooper</p>
                      </li>
                      <li class="d-flex justify-content-between align-items-center">
                        <p>Modified</p>
                        <p class="fw-bold">12th Aug, 2020</p>
                      </li>

                      <li class="d-flex justify-content-between align-items-center">
                        <p>Created</p>
                        <p class="fw-bold">01 Oct, 2019</p>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane fade" id="activity-tab" role="tabpanel" aria-labelledby="activity-tab">
                    <h6 class="file-manager-title my-2">Today</h6>
                    <div class="d-flex align-items-center mb-2">
                      <div class="avatar avatar-sm me-50">
                        <img src="/app-assets/images/avatars/5-small.png" alt="avatar" width="28"/>
                      </div>
                      <div class="more-info">
                        <p class="mb-0">
                          <span class="fw-bold">Mae</span>
                          shared the file with
                          <span class="fw-bold">Howard</span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-sm bg-light-primary me-50">
                        <span class="avatar-content">SC</span>
                      </div>
                      <div class="more-info">
                        <p class="mb-0">
                          <span class="fw-bold">Sheldon</span>
                          updated the file
                        </p>
                      </div>
                    </div>
                    <h6 class="file-manager-title mt-3 mb-2">Yesterday</h6>
                    <div class="d-flex align-items-center mb-2">
                      <div class="avatar avatar-sm bg-light-success me-50">
                        <span class="avatar-content">LH</span>
                      </div>
                      <div class="more-info">
                        <p class="mb-0">
                          <span class="fw-bold">Leonard</span>
                          renamed this file to
                          <span class="fw-bold">menu.js</span>
                        </p>
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-sm me-50">
                        <img src="/app-assets/images/portrait/small/avatar-s-1.jpg" alt="Avatar" width="28"/>
                      </div>
                      <div class="more-info">
                        <p class="mb-0">
                          <span class="fw-bold">You</span>
                          shared this file with Leonard
                        </p>
                      </div>
                    </div>
                    <h6 class="file-manager-title mt-3 mb-2">3 days ago</h6>
                    <div class="d-flex align-items-start">
                      <div class="avatar avatar-sm me-50">
                        <img src="/app-assets/images/portrait/small/avatar-s-1.jpg" alt="Avatar" width="28"/>
                      </div>
                      <div class="more-info">
                        <p class="mb-50">
                          <span class="fw-bold">You</span>
                          uploaded this file
                        </p>
                        <img src="/app-assets/images/icons/js.png" alt="Avatar" class="me-50" height="24"/>
                        <span class="fw-bold">app.js</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- File Info Sidebar Ends -->

        <!-- Create New Folder Modal Starts-->
        <new-folder-modal ref="newFolderModal" @refresh-file-manager="initData"/>
        <!-- /Create New Folder Modal Ends -->

        <!-- Rename Folder Modal Starts-->
        <rename-folder-modal ref="renameFolderModal" @refresh-file-manager="initData"/>
        <!-- /Rename Folder Modal Ends -->

        <!-- New File Modal Starts-->
        <new-file-modal ref="newFileModal" @refresh-file-manager="initData"/>
        <!-- /New File Modal Ends -->

        <!-- Rename File Modal Starts-->
        <rename-file-modal ref="renameFileModal" @refresh-file-manager="initData"/>
        <!-- /Rename File Modal Ends -->

      </div>
    </div>
  </div>
</template>

<script>

import newFolderModal from "@/views/pages/_components/_fileManager/_modal/newFolderModal.vue"
import renameFolderModal from "@/views/pages/_components/_fileManager/_modal/renameFolderModal.vue"
import newFileModal from "@/views/pages/_components/_fileManager/_modal/newFileModal.vue"
import renameFileModal from "@/views/pages/_components/_fileManager/_modal/renameFileModal.vue"
import {BDropdown, BDropdownItem, BOverlay} from "bootstrap-vue"

export default {
  components: {
    newFolderModal,
    renameFolderModal,
    newFileModal,
    renameFileModal,
    BDropdown,
    BDropdownItem,
    BOverlay
  },
  mounted() {
    const appContent = document.querySelector('.app-content.content')
    appContent.classList.add('file-manager-application')
    this.initData()
  },
  data() {
    return {
      id: '',
      role: 'fileManager',
      rootId: '',
      parentId: '',
      data: '',
      foldersCount: 0,
      filesCount: 0,
      canCreateSubFolder: false,
      canUploadFile: false,
      mode: 'list',
      loading: false,
    }
  },
  methods: {
    initData() {
      this.loading = true
      this.id = this.$route.params.id
      this.foldersCount = 0
      this.filesCount = 0
      if (!this.id) {
        this.$store.dispatch('fetchFilesForFileManager').then(response => {
          this.rootId = response.data.rootFolderId
          this.data = response.data.data.children
          this.canCreateSubFolder = response.data.data.canCreateSubFolder
          this.canUploadFile = response.data.data.canUploadFile
          if (response.data.data.parent) {
            this.parentId = response.data.data.parent.uuid
          }
          Object.keys(this.data).forEach(key => {
            if (this.data[key].mimeType === 'inode/directory') {
              this.foldersCount++
            } else {
              this.filesCount++
            }
          })
        }).catch(error => {
          if (error.response.status === 403) {
            this.$helpers.showErrorToast(this.$t('text.api.response.permissionDenied'))
            this.$router.push({name: 'file-manager'})
          }
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.$store.dispatch('fetchFolderForFileManager', this.id).then(response => {
          this.rootId = response.data.rootFolderId
          this.data = response.data.data.children
          this.canCreateSubFolder = response.data.data.canCreateSubFolder
          this.canUploadFile = response.data.data.canUploadFile
          if (response.data.data.parent) {
            this.parentId = response.data.data.parent.uuid
          }
          Object.keys(this.data).forEach(key => {
            if (this.data[key].mimeType === 'inode/directory') {
              this.foldersCount++
            } else {
              this.filesCount++
            }
          })
        }).catch(error => {
          if (error.response.status === 403) {
            this.$helpers.showErrorToast(this.$t('text.api.response.permissionDenied'))
            this.$router.push({name: 'file-manager'})
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },
    moveUp(uuid) {
      if (uuid === this.rootId) {
        this.$router.push({name: 'file-manager'})
      } else {
        this.$router.push({name: 'file-manager-folder', params: {id: this.parentId}})
      }
    },
    showNewFolderModal() {
      this.$refs.newFolderModal.formData.folderName = ''
      this.$refs.newFolderModal.formData.parentId = this.id
      this.$bvModal.show('form-add-new-folder-modal')
    },
    showRenameFolderModal(folder) {
      if (folder.canCreateSubFolder) {
        this.$refs.renameFolderModal.formData.folderName = folder.alias
        this.$refs.renameFolderModal.formData.id = folder.uuid
        this.$bvModal.show('form-rename-folder-modal')
      } else {
        this.$helpers.showErrorToast(this.$t('text.api.response.folderCantRename'))
      }
    },
    downloadFolderAsZip(uuid, name) {
      this.loading = true
      this.$store.dispatch('downloadFolderAsZip', uuid).then(response => {
        let filename = this.$helpers.slugify(name) + '-' + this.$helpers.getCurrentDate() + '.zip'

        const link = document.createElement('a')
        link.href = response.data.url
        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(error => {
        console.log(error)
        this.$helpers.showErrorToast();
      }).finally(() => {
        this.loading = false
      })
    },
    showNewFileModal() {
      this.$refs.newFileModal.file = null
      this.$refs.newFileModal.formData.parentId = this.id
      this.$bvModal.show('form-add-new-file-modal')
    },
    downloadFile(id, filename) {
      this.$store.dispatch('downloadFile', id).then(response => {
        const blob = new Blob([response.data], {type: response.headers['Content-Type']})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)

        this.$helpers.showSuccessToast()
      })
    },
    previewFile(id) {
      this.$store.dispatch('getTemporaryUrlForFile', id).then(response => {
        window.open(response.data, '_blank');
      })
    },
    deleteFolder(uuid, deletable) {
      if (deletable) {
        this.$bvModal
            .msgBoxConfirm(this.$t('confirm.message.delete'), {
              title: this.$t('modal.title.confirm'),
              size: 'sm',
              okVariant: 'primary',
              okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
              cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                this.$store.dispatch('deleteFolder', uuid).then(response => {
                  this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id: response.data.name}))

                  this.initData()
                }).catch(() => {
                  this.$helpers.showErrorToast(this.$t('text.api.response.folderCantDelete'))
                })
              }
            })
      } else {
        this.$helpers.showErrorToast(this.$t('text.api.response.folderCantDeleteItsNotEmpty'))
      }
    },
    showRenameFileModal(file) {
      const filenameParts = file.alias.split('.')
      this.$refs.renameFileModal.formData.filename = filenameParts[0]
      this.$refs.renameFileModal.formData.id = file.id
      this.$bvModal.show('form-rename-file-modal')
    },
    deleteFile(id, deletable) {
      if (deletable) {
        this.$bvModal
            .msgBoxConfirm(this.$t('confirm.message.delete'), {
              title: this.$t('modal.title.confirm'),
              size: 'sm',
              okVariant: 'primary',
              okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
              cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                this.$store.dispatch('deleteFile', id).then(response => {
                  this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id: response.data.name}))

                  this.initData()
                }).catch(() => {
                  this.$helpers.showErrorToast(this.$t('text.api.response.fileCantDelete'))
                })
              }
            })
      } else {
        this.$helpers.showErrorToast(this.$t('text.api.response.folderCantDeleteItsNotEmpty'))
      }
    }
  },
  watch: {
    $route() {
      this.initData()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/file-manager.scss';
</style>

<style>
.dropdown-no-padding .btn.dropdown-toggle {
  padding: 0;
}
</style>
