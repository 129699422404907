<template>
  <b-modal
      id="form-rename-folder-modal"
      ref="form-rename-folder-modal"
      cancel-variant="outline-secondary"
      :ok-title="$t('label.button.save')"
      :cancel-title="$t('label.button.cancel')"
      @ok="handleOk"
      centered
      :title="$t('modal.title.rename.folder')"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form @submit.stop="handleOk">
        <b-row>
          <b-col md="12">
            <b-form-group
                :label="$t('form.name')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('form.name').toLowerCase()"
                  :clearable="false"
                  rules="required"
                  vid="folderName"
              >
                <b-form-input
                    v-model="formData.folderName"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('form.name').toLowerCase()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      formData: {
        id: '',
        folderName: ''
      }
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault()

      let fd = JSON.parse(JSON.stringify(this.formData))

      this.$store.dispatch('renameFolder', fd).then(() => {
        this.$helpers.showSuccessToast()
        this.$emit('refresh-file-manager')
        this.$nextTick(() => {
          this.$bvModal.hide('form-rename-folder-modal')
        })
      }).catch(error => {
        if (error.response.status === 422) {
          this.$refs.simpleRules.setErrors(error.response.data.errors)
        } else if (error.response.status === 400) {
          this.$helpers.showErrorToast(error.response.data.message)
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
