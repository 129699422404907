<template>
  <b-modal
      id="form-add-new-file-modal"
      ref="form-add-new-file-modal"
      cancel-variant="outline-secondary"
      :ok-title="$t('label.button.upload')"
      :cancel-title="$t('label.button.cancel')"
      @ok="handleOk"
      centered
      size="lg"
      :title="$t('modal.title.new.file')"
  >
    <b-alert show variant="primary" class="mb-1">
      <div class="alert-body">
        <feather-icon icon="InfoIcon" size="24"/>
        {{ $t('card.description.file.upload') }}
      </div>
    </b-alert>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col :sm="file ? 10 : 12">
            <b-form-file
                v-model="file"
                :placeholder="$t('label.files.choose')"
                :drop-placeholder="$t('label.files.drop')"
                :browse-text="$t('label.files.browse')"
                accept="
                    application/pdf,
                    image/bmp,
                    application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                    image/gif,
                    image/jpeg,
                    application/vnd.oasis.opendocument.spreadsheet,
                    application/vnd.oasis.opendocument.text,
                    image/png,
                    application/vnd.ms-powerpoint,
                    application/vnd.openxmlformats-officedocument.presentationml.presentation,
                    image/svg+xml,
                    application/x-tar,
                    text/plain,
                    text/csv,
                    image/webp,
                    application/vnd.ms-excel,
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    application/zip,
                    application/x-7z-compressed
                  "
            />
          </b-col>
          <b-col v-if="file" sm="2">
            <b-button
                variant="danger"
                class="float-left mr-1"
                @click.prevent="() => {file = null}"
            >
              <feather-icon
                  icon="Trash2Icon"
                  size="15"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import BCardCode from "@core/components/b-card-code";
import {BForm, BCol, BRow, BFormFile, BButton, BAlert} from "bootstrap-vue"

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BCol,
    BRow,
    BFormFile,
    BButton,
    BAlert,
  },
  data() {
    return {
      file: null,
      formData: {
        parentId: ''
      }
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault()

      const fd = new FormData();
      fd.append('file', this.file)
      fd.append('parentId', this.formData.parentId)

      this.$store.dispatch('uploadFile', fd).then(() => {
        this.$helpers.showSuccessToast()
        this.$emit('refresh-file-manager')
        this.$nextTick(() => {
          this.$bvModal.hide('form-add-new-file-modal')
        })
      }).catch(error => {
        if (error.response.status === 422) {
          this.$refs.simpleRules.setErrors(error.response.data.errors)
        } else if (error.response.status === 400) {
          this.$helpers.showErrorToast(error.response.data.message)
        } else if (error.response.status === 413) {
          this.$helpers.showErrorToast(this.$t('text.api.response.tooBigFile'))
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
